<template>
  <el-dialog
      :title="'分配移动端权限('+role.roleName+')'"
      width="40%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-row>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="页面权限" name="page">
          <div style="max-height:40vh;overflow: auto;min-height: 15vh">
            <el-tree
                :data="moduleTree"
                show-checkbox
                node-key="id"
                ref="moduleTree"
                :check-strictly="false"
                :default-checked-keys="defaultArr"
                :props="defaultProps">
            </el-tree>
          </div>
        </el-tab-pane>
        <el-tab-pane label="按钮权限" name="button">
          <div style="max-height:40vh;overflow: auto;min-height: 15vh">
            <el-tree
                :data="buttonTree"
                show-checkbox
                node-key="id"
                ref="buttonTree"
                :check-strictly="false"
                :default-expand-all="true"
                :default-checked-keys="buttonArray"
                :props="buttonProps">
            </el-tree>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button size="medium" @click="close">取消</el-button>
      <el-button type="primary" size="medium" @click="checkTenantModule">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
name: "role-mobile-permission",
  data() {
    return {

      tenant: this.role.tenantCrop,
      moduleTree:[],
      defaultArr:[],
      defaultProps: {
        children: 'child',
        label: 'viewName',
      },
      dialogState: this.state,
      activeName: "page",
      buttonTree: [],
      buttonArray: [],
      buttonProps: {
        label: "buttonName"
      },
    }
  },
  props: {
    role: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    state: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  created() {
    this.queryAllModuleTree();
    this.queryButtonsByTenant();
  },
  methods: {
    checkTenantModule:function (){
      let value = "是否更新权限?:"
      this.$confirm(value, '更新:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.saveTenantModule();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //添加用户
    saveTenantModule: function () {
        //获取全部树中的虚拟节点
        let fakeButtonIds=this.buttonTree.map(k=>k.id)
        //选中的数组
        let chooseButtonArr=this.$refs.buttonTree.getCheckedKeys();
        //通过循环删除假节点id
        let finalButtonIds = chooseButtonArr.filter(function(value) {
            return fakeButtonIds.indexOf(value) === -1;
        });

      this.$axios({
        method: "POST",
        url: "/role/saveRoleMobileViews",
        data: {
          roleId:this.role.id,
          moduleIds:this.$refs.moduleTree.getCheckedKeys(),
          buttonIds: finalButtonIds,
          type: 2,
        }
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success("更新成功!")
          let self = this;
          setTimeout(function () {
            self.close()
          }, 1500)
        } else {
          this.$message.error(response.data.msg)
        }
      });
    },
    //查询所有模块
    queryAllModuleTree() {
      this.$axios({
        method:"GET",
        url:"/mobileView/queryMobileViewTree",
        params:{
          tenantCrop:this.tenant
        }
      }).then(response=>{
        this.moduleTree=response.data.data
        console.log(this.moduleTree)
        this.queryTenantModuleIds()
      })
    },
    //查询该公司的moduleId
    queryTenantModuleIds(){
      this.$axios({
        method:"GET",
        url:"/mobilePermission/queryViewIdsByRoleId",
        params:{
          roleId:this.role.id
        }
      }).then(response=>{
        this.defaultArr=response.data.data
      })
    },
    close: function () {
      this.$emit("close", false);
    },
    queryButtonsByTenant() {
      this.$axios({
        method: "GET",
        url: "/button/queryButtonTree",
        params: {
          tenantCrop: this.tenant,
          type: 2
        },
      }).then(response=>{
        this.buttonTree = response.data.data
        this.queryRoleButtonIds()
      })
    },
    queryRoleButtonIds() {
      this.$axios({
        method: "GET",
        url: "/buttonPermission/queryRoleButtonIds",
        params: {
          roleId: this.role.id,
          type: 2
        }
      }).then(response => {
        this.buttonArray = response.data.data
      })
    },
  }
}
</script>

<style scoped>

</style>