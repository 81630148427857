<template>
  <div>
    <br>
    <el-row style="padding-left: 10px">
      <el-button type="primary" @click="dialog.addRoleState=true" style="margin-bottom: 10px">添加职位</el-button>
      <el-button type="warning" @click="backToDefaultConfirm">一键还原</el-button>
      <el-table
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :data="table.roleList"
          border
          max-height="650"
          :default-sort = "{prop: 'id', order: 'ascending'}"
          style="width: 99%">
        <el-table-column prop="roleName" label="职位名称" align="center"></el-table-column>
        <el-table-column prop="isValid" label="是否可用" align="center" >
          <template slot-scope="scope">
            <p :style="{color:scope.row.isValid===1?'green':'red'}">{{scope.row.isValid===1?"可用":"不可用"}}</p>
          </template>
        </el-table-column>
        <el-table-column width="450" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="success" size="mini" @click="openEdit(scope.row)">编辑</el-button>
              <el-button type="danger" size="mini" @click="delRole(scope.row)">删除</el-button>
              <el-button type="warning" size="mini" @click="openPermission(scope.row)">分配PC端页面</el-button>
              <el-button type="info" size="mini" @click="openMobileView(scope.row)">分配移动端页面</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <roleAdd v-if="dialog.addRoleState" :state="dialog.addRoleState" @close="close"/>
    <roleEdit v-if="dialog.editRoleState" :role="dialog.role" :state="dialog.editRoleState" @close="close"/>
    <rolePermission v-if="dialog.permissionState" :role="dialog.role" :state="dialog.permissionState" @close="close"/>
    <roleMobilePermission v-if="dialog.mobileViewState" :role="dialog.role" :state="dialog.mobileViewState" @close="close"/>
  </div>
</template>

<script>
import roleAdd from "@/pages/emp/role/role-add";
import roleEdit from "@/pages/emp/role/role-edit";
import rolePermission from "@/pages/emp/role/role-permission";
import roleMobilePermission from "@/pages/emp/role/role-mobile-permission";

export default {
  name: "role-manage",
  data(){
    return{
      empName:"",
      shopId:"",
      role:"",
      isValid:"",
      tenantCrop:localStorage.getItem("tenantCrop"),
      table:{
        roleList:[],
        page:1,
        limit:100,
        total:0,
      },
      shopArray:[],
      roleArray:[],
      dialog:{
        addRoleState:false,
        editRoleState:false,
        permissionState:false,
        mobileViewState:false,
        role:{},
      }

    }
  },
  components:{
    roleAdd,
    roleEdit,
    rolePermission,
    roleMobilePermission,
  },
  created() {
    this.queryRoleList();
  },
  methods:{
    search(){
      this.table.page=1;
      this.queryEmpList();
    },
    //查询客户列表
    queryRoleList:function (){
      this.$axios({
        method:"GET",
        url:"/role/queryRoleList",
        params:{
          page:this.table.page,
          limit:this.table.limit,
          tenantCrop:this.tenantCrop,
        }
      }).then(response=>{
        this.table.total=response.data.data.total;
        this.table.roleList=response.data.data.list;
      })
    },
    //打开编辑界面
    openEdit(data){
      this.dialog.editRoleState = true
      this.dialog.role = data
    },
    //打开电脑端权限
    openPermission(data){
      this.dialog.permissionState=true;
      this.dialog.role = data
    },
    //打开电脑端权限
    openMobileView(data){
      this.dialog.mobileViewState=true;
      this.dialog.role = data
    },
    //删除客户
    delRole(data){
      this.$confirm("删除职位", '删除职位:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method:"delete",
          url:"/role/deleteRole",
          params:{
            roleId:data.id
          }
        }).then(response=>{
          if (response.data.code === 200) {
            this.$message.success("删除成功!")
            let self=this;
            setTimeout(function (){self.queryRoleList()},1000)
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //更改每页长度
    handleSizeChange:function (val){
      this.table.limit=val;
      this.queryRoleList();
    },
    handleCurrentChange(val) {
      this.table.page =val
      this.queryRoleList()
    },
    close(){
      this.dialog.addRoleState=false
      this.dialog.editRoleState=false
      this.dialog.permissionState=false
      this.dialog.mobileViewState=false
      this.queryRoleList();
    },
    // 一键还原确认
    backToDefaultConfirm() {
      this.$confirm('<span style="color: #ff4d51;font-size: 16px">此操作会将系统默认角色(收款人、化妆师、客服、礼服师、行政)的权限还原至系统默认设置的权限且<b>无法恢复</b>,请谨慎操作！</span>','一键还原', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.backToDefault()
      }).catch(() => {})
    },
    // 一键还原
    backToDefault() {
      this.$axios({
        method: "post",
        url: "/defaultPermission/backToDefaultPermission",
        params: {
         tenantCrop: localStorage.getItem("tenantCrop")
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '恢复成功': response.data.msg,
          type: flag ? 'success':'error',
          duration: 1000,
        });
      })
    }
  }
}
</script>

<style scoped>

</style>