<template>
  <el-dialog
      title="添加员工"
      width="30%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" style="margin-left: 40px" ref="form">
      <el-row>
        <el-col :span="15">
          <el-form-item label="职位名" prop="roleName">
            <el-input v-model="form.roleName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="15">
          <el-form-item label="职位状态" prop="isValid">
            <el-radio-group v-model="form.isValid">
              <el-radio :label="1">在职</el-radio>
              <el-radio :label="0">离职</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="addRoleCheck()">提 交</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "role-edit",
  data() {
    return {
      form: {
        //公司名
        id:this.role.id,
        roleName: this.role.roleName,
        isValid: this.role.isValid,
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      rules: {
        roleName: [
          {required: true, message: '请输入职位名称', trigger: 'blur'},
        ],
        isValid: [
          {required: true, message: '请选择是否有效', trigger: 'change'},
        ],
      },
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    role:{
      type:Object,
      required: true,
      default(){return {}}
    }
  },
  methods: {
    //检查订单校验
    addRoleCheck: function () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.addRole();
        }
      })
    },
    //添加员工
    addRole: function () {
      let value = "是否修改该权限?"
      this.$confirm(value, '修改权限:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/role/updateRole",
          params: this.form
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("修改成功!")
            let self = this;
            setTimeout(function () {
              self.close()
            }, 1500)
          } else {
            this.$message.error(response.data.msg)
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>

</style>